/**
 * Publication is the domain, without the TLD.
 *
 * @see parseHostname() function from the "@ta-interaktiv/parse-hostname" package
 */
export type Publication =
  | "web"
  | "firebaseapp"
  | "24heures"
  | "bazonline"
  | "bernerzeitung"
  | "derbund"
  | "landbote"
  | "lematin"
  | "tagesanzeiger"
  | "tdg"
  | "zsz"
  | "zuonline";

export type PublicationMatcher<T> = { [k in Publication]: T };
export const casePublication = <T>(m: PublicationMatcher<T>, p: Publication) => m[p];

/**
 * A version of 'casePublication' that accepts a string instead of 'Publication',
 * but also can return undefined if the string isn't one of the known publications.
 */
export const _casePublication = <T>(m: PublicationMatcher<T>, p: string): undefined | T =>
  m[p as Publication];
