import * as firebase from "firebase";
import * as React from "react";
import { History, createMemoryHistory } from "history";
import { useIntl } from "react-intl";

/**
 * Read-only environment that is made available all components in the app.
 * This object is conceptually equivalent to a global variable. But we don't
 * use global variables and instead pass it down the three using the React
 * context.
 */
export interface Env {
  history: History;
  db: firebase.firestore.Firestore;
}

export const Env = React.createContext<Env>({
  history: createMemoryHistory(),
  db: firebase.firestore()
});

export const useEnv = () => {
  return { ...React.useContext(Env), intl: useIntl() };
};
