import * as firebase from "firebase";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { App } from "./app";

const main = async () => {
  const containerElement = document.getElementById("app");

  const history = createBrowserHistory();
  const db = firebase.firestore();

  ReactDOM.render(
    <App history={history} db={db} />,
    containerElement
  );
};

main().catch(err => {
  console.error("main", err);
});
