export const home = "/";

export const nr = (year: string, cantonId?: string) => {
  if (cantonId) {
    return `/${year}/nr/${cantonId}`;
  } else {
    return `/${year}/nr/`;
  }
};

export const sr = (year: string) => {
  return `/${year}/sr`;
};

export const party = (year: string, partyId: string) => {
  return `/${year}/party/${partyId}`;
};

export const partyMuniAbs = (year: string, partyId: string) => {
  return `/${year}/party/${partyId}/m/abs`;
};

export const partyMuniDiff = (year: string, partyId: string) => {
  return `/${year}/party/${partyId}/m/diff`;
};
