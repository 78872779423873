import * as upstream from "@ta-interaktiv/parse-hostname";
import { _casePublication } from "../domain/publication";

export interface ParsedResults {
  projectDomain: string;
  publicationDomain: string;
  publicationName: string;
}

/**
 * Wrapper around the upstream version of parseHostname (from the
 * "@ta-interaktiv/parse-hostname" package). It adds support to the tamedia
 * ballot dashboard staging environment domain.
 *
 * If you access the project through the "tamedia-ballot-dashboard-dev.firebaseapp.com"
 * domain, it will behave like localhost, ie. it will treat it as "tagesanzeiger.ch".
 */
export const parseHostname = () => {
  const res = upstream.parseHostname();

  if (res.projectDomain.match(/\.(firebaseapp\.com|web\.app)$/)) {
    return {
      ...res,
      projectDomain: "interaktiv.tagesanzeiger.ch",
      publicationDomain: "tagesanzeiger.ch",
      publicationName: "tagesanzeiger"
    };
  } else {
    return res;
  }
};

/**
 * Returns the domain of the interaktiv. / interactif. website.
 *
 * Example: "interaktiv.tagesanzeiger.ch"
 */
export const publicationInteractiveDomain = ({
  publicationName,
  publicationDomain
}: ParsedResults) => {
  const subDomain = _casePublication(
    {
      "24heures": "interactif",
      bazonline: "interaktiv",
      bernerzeitung: "interaktiv",
      derbund: "interaktiv",
      lematin: "interactif",
      tagesanzeiger: "interaktiv",
      tdg: "interactif",
      firebaseapp: "interaktiv",
      web: "interaktiv",
      landbote: "interaktiv",
      zsz: "interaktiv",
      zuonline: "interaktiv"
    },
    publicationName
  );

  return `${subDomain || "interaktiv"}.${publicationDomain}`;
};
