export const grey = {
  "50": "#FAFAFA",
  "100": "#F5F5F5",
  "200": "#EAEAEA",
  "300": "#DDDDDD",
  "400": "#CCCCCC",
  "500": "#9E9E9E",
  "600": "#757575",
  "700": "#616161",
  "800": "#424242",
  "900": "#212121"
};

export const green = {
  "50": "#FFFFFF",
  "100": "#FFFFFF",
  "200": "#E6F5D0",
  "300": "#FFFFFF",
  "400": "#B8E186",
  "500": "#7FBC41",
  "600": "#FFFFFF",
  "700": "#4D9221",
  "800": "#276419",
  "900": "#FFFFFF"
};

export const purple = {
  "50": "#FFFFFF",
  "100": "#FFFFFF",
  "200": "#FDE0EF",
  "300": "#FFFFFF",
  "400": "#F1B6DA",
  "500": "#DE77AE",
  "600": "#FFFFFF",
  "700": "#C51B7D",
  "800": "#8E0152",
  "900": "#FFFFFF"
};

// https://hihayk.github.io/scale/#2/7/50/90/0/0/0/-0/1190D7/17/144/215
export const blue = {
  "50": "#E7F4FB",
  "100": "#C9E6F6",
  "200": "#AAD7F1",
  "300": "#8BC9EC",
  "400": "#6DBBE6",
  "500": "#4EADE1",
  "600": "#309EDC",
  "700": "#1190D7",
  "800": "#0D6CA1",
  "900": "#09486C"
};
