import * as React from "react";

import { color } from "../../materials/index";

export class MissingValuePattern extends React.PureComponent<{ id: string }> {
  render() {
    const { id } = this.props;

    const width = 14;
    const height = 14;
    const fill = "white";
    const stroke = color.lightGrey;

    return (
      <pattern
        id={id}
        patternUnits="userSpaceOnUse"
        patternContentUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={width}
        height={height}
      >
        <rect x={0} y={0} width={width} height={height} fill={fill} />
        <line x1={1} y1={10} x2={5} y2={14} stroke={stroke} />
        <line x1={5} y1={10} x2={1} y2={14} stroke={stroke} />
        <line x1={8} y1={3} x2={12} y2={7} stroke={stroke} />
        <line x1={12} y1={3} x2={8} y2={7} stroke={stroke} />
      </pattern>
    );
  }
}

export class DiagonalStripePattern extends React.PureComponent<{ id: string; stroke: string }> {
  render() {
    const { id, stroke } = this.props;

    const width = 4;
    const height = 4;
    const fill = color.grey["50"];
    const strokeWidth = 0.5;

    return (
      <pattern
        id={id}
        patternUnits="userSpaceOnUse"
        patternContentUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={width}
        height={height}
      >
        <rect x={0} y={0} width={width} height={height} fill={fill} />
        <line
          x1={0}
          y1={height / 2}
          x2={width / 2}
          y2={0}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="square"
        />
        <line
          x1={width / 2}
          y1={height}
          x2={width}
          y2={height / 2}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="square"
        />
      </pattern>
    );
  }
}

export class Blur extends React.PureComponent<{ id: string }> {
  render() {
    const { id } = this.props;

    return (
      <filter id={id}>
        <feGaussianBlur stdDeviation="4" />
      </filter>
    );
  }
}

export class DropShadow extends React.PureComponent<{ id: string }> {
  render() {
    const { id } = this.props;

    return (
      <filter id={id} x="-50%" y="-50%" width="200%" height="200%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
        <feOffset dx="0" dy="2" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.75" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    );
  }
}
