import * as Sentry from "@sentry/browser";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    beforeBreadcrumb(bc) {
      /*
       * Ignore XHR to the Firestore API, these breadcrumbs are irrelevant to us
       * and only clutter up the history.
       */
      if (bc.category === "xhr" && bc.data.url.startsWith("https://firestore.googleapis.com")) {
        return null;
      } else {
        return bc;
      }
    }
  });

  Sentry.configureScope(scope => {
    scope.setTag("version", process.env.VERSION);
  });
}

import "core-js/stable";
import "regenerator-runtime/runtime";
import "resize-observer-polyfill";
import "isomorphic-unfetch";
import "intersection-observer";

if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/de");
  require("@formatjs/intl-pluralrules/dist/locale-data/fr");
}

if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/de");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/fr");
}

if (process.env.NODE_ENV !== "production") {
  delete require("react-intl").FormattedMessage.propTypes;
}
