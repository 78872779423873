import "../prelude";

import * as Sentry from "@sentry/browser";
import * as React from "react";
import { IntlProvider, defineMessages } from "react-intl";
import { Helmet } from "react-helmet";
import * as t from "io-ts";
import * as mitt from "mitt";
import { History } from "history";
import { parseQueryParams, useEnv } from "../lib/index";
import { Locale, intlFormats } from "../domain/intl";
import { color } from "../materials/index";
import { DiagonalStripePattern, DropShadow, Blur } from "../components/ballot-map/patterns";

import messagesDe from "../locale/de.json";
import messagesFr from "../locale/fr.json";
import { Global, css } from "@emotion/core";
import { parseHostname } from "../lib/parseHostname";
import { _casePublication } from "../domain";
import { AppVariant } from "../domain/appVariant";

const LocaleQueryParams = t.interface({
  locale: Locale
});

function localeForPublication(publicationName: string): Locale {
  return (
    _casePublication<Locale>(
      {
        "24heures": "fr",
        bazonline: "de",
        bernerzeitung: "de",
        derbund: "de",
        lematin: "fr",
        tagesanzeiger: "de",
        tdg: "fr",
        firebaseapp: "de",
        web: "de",
        landbote: "de",
        zsz: "de",
        zuonline: "de"
      },
      publicationName
    ) || "de"
  );
}

const emitter: mitt.Emitter = new mitt();

// Expose app configuration to the developer console
(window as any).app = {
  localeDe() {
    emitter.emit("changeLocale", { locale: "de" });
  },
  localeFr() {
    emitter.emit("changeLocale", { locale: "fr" });
  }
};

function messagesForLocale(locale: Locale) {
  switch (locale) {
    case "de":
      return messagesDe;
    case "fr":
      return messagesFr;
  }
}

// -----------------------------------------------------------------------------
// Page Head component

const appMessages = {
  docs: null,
  election: defineMessages({
    appTitle: {
      id: "eWy6YT7",
      defaultMessage: "Eidgenössische Wahlen",
      description: "meta page title (Election)"
    },
    appDescription: {
      id: "iiSV7fi",
      defaultMessage:
        "Die Schweiz stimmt ab. Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale.",
      description: "meta page description (Election)"
    },
    shareTitle: {
      id: "DUXBGsn",
      defaultMessage: "Eidgenössische Wahlen",
      description: "meta share title (Election)"
    },
    shareDescription: {
      id: "VMYmgkJ",
      defaultMessage:
        "Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale.",
      description: "meta share description (Election)"
    }
  }),
  ballot: defineMessages({
    appTitle: {
      id: "head.appTitle",
      defaultMessage: "Abstimmungen Schweiz"
    },
    appDescription: {
      id: "head.appDescription",
      defaultMessage:
        "Die Schweiz stimmt ab. Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale."
    },
    shareTitle: {
      id: "head.shareTitle",
      defaultMessage: "Die Schweiz stimmt ab"
    },
    shareDescription: {
      id: "head.shareDescription",
      defaultMessage:
        "Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale."
    }
  })
};

interface Props {
  locale: string;
  localeTag: string;
  appVariant: AppVariant;
}

const PageHead = ({ locale, localeTag, appVariant }: Props) => {
  const { intl } = useEnv();
  const host = parseHostname();

  const headDescriptors = appMessages[appVariant];

  /** Not needed for catalog */
  if (!headDescriptors) return null;

  return (
    <Helmet>
      <html lang={`${locale}-${localeTag}`} />
      <title>{intl.formatMessage(headDescriptors.appTitle)}</title>
      <meta name="description" content={intl.formatMessage(headDescriptors.appDescription)} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={intl.formatMessage(headDescriptors.shareTitle)} />
      <meta
        property="twitter:description"
        content={intl.formatMessage(headDescriptors.shareDescription)}
      />
      <meta property="og:title" content={intl.formatMessage(headDescriptors.shareTitle)} />
      <meta
        property="og:description"
        content={intl.formatMessage(headDescriptors.shareDescription)}
      />
      <meta
        property="og:image"
        content={`https://${host.publicationDomain}/${appVariant}-dashboard-default-share-image.jpg`}
      />
    </Helmet>
  );
};

// -----------------------------------------------------------------------------
// Runtime component

export interface RuntimeProps {
  history: History;
  children: React.ReactNode;
  appVariant: AppVariant;
}

export type RuntimeState = Readonly<{
  locale: Locale;
  localeTag: "CH";
}>;

/**
 * This is the 'onError' handler of the react-intl IntlProvider. We disable it because
 * the messages are only annoying during development.
 */
function onError(_error: string): void {
  /* Intentionally empty */
}

export const Runtime = ({ history, children, appVariant }: RuntimeProps) => {
  const [{ locale, localeTag }, setLocale] = React.useState(() => {
    const initialLocale = parseQueryParams(history, LocaleQueryParams).fold(
      () => localeForPublication(parseHostname().publicationName),
      ({ locale }) => locale
    );

    return {
      locale: initialLocale,
      localeTag: "CH"
    };
  });

  React.useEffect(() => {
    const onChangeLocale = ({ locale }: { locale: Locale }) => {
      setLocale({ locale, localeTag });
    };
    emitter.on("changeLocale", onChangeLocale);
    return () => {
      emitter.off("changeLocale", onChangeLocale);
    };
  }, [setLocale, localeTag]);

  return (
    <>
      <Global styles={globalStyles} />

      <svg style={{ height: 0 }}>
        <defs>
          <DiagonalStripePattern id="diagonal-stripe-yea" stroke={color.yea} />
          <DiagonalStripePattern id="diagonal-stripe-nay" stroke={color.nay} />
          <DiagonalStripePattern id="diagonal-stripe-inProgress" stroke={color.grey["500"]} />
          <DropShadow id="dropshadow" />
          <Blur id="blur" />
        </defs>
      </svg>
      <IntlProvider
        key={
          locale /* Trigger render on locale change. See https://github.com/yahoo/react-intl/issues/243 */
        }
        locale={`${locale}-${localeTag}`}
        formats={intlFormats}
        messages={messagesForLocale(locale)}
        onError={onError}
      >
        <ErrorBoundary locale={locale}>
          <PageHead locale={locale} localeTag={localeTag} appVariant={appVariant} />
          {children}
        </ErrorBoundary>
      </IntlProvider>
    </>
  );
};

class ErrorBoundary extends React.PureComponent<{ locale: Locale }> {
  state = { error: undefined, eventId: undefined };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      /*
       * If we ever decide to have a really nice "The application has crashed" webpage,
       * consider adding this feedback button to it.
       *
       * See https://docs.sentry.io/enriching-error-data/user-feedback.
       *
       * <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>
       */

      return <div>Error.</div>;
    } else {
      return this.props.children;
    }
  }
}

const globalStyles = css`
  /**
   * Font faces
   */
  @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");
  @import url("https://interaktiv.tagesanzeiger.ch/static/themes/abstimmungen.css");

  /**
   * Reset
   * Based on normalize.css, sanitize.css, minireset.css
   */

  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  /* Certain ad-scripts (that are injected by the parent window) insert an img
   * tag into the body which messes up the DimensionWatcher. Hide those elements
   * so that they don't affect the dimensions of the iframe. */
  body > img {
    display: none;
  }

  /* Align with TagesAnzeiger */
  a,
  a:hover {
    text-decoration: none;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section,
  figcaption,
  figure,
  main,
  canvas,
  svg {
    display: block;
  }

  svg:not(:root) {
    overflow: hidden;
  }
  img {
    border-style: none;
    height: auto;
    max-width: 100%;
  }
  iframe {
    border: 0;
  }

  ul,
  ol {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  :root {
    --ui-font-stack: "Source Sans Pro", sans-serif;
  }
`;
