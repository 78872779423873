import { Percent, unPercent } from './percent'

// ----------------------------------------------------------------------------
// Outcome of the vote, yea (accepted) / nay (rejected).

export type Outcome = 'yea' | 'nay'

export const mkOutcome = (x: Outcome): Outcome =>
  x

export interface OutcomeMatcher<T> {
  yea: T,
  nay: T,
}

export const caseOutcome = <T>(m: OutcomeMatcher<T>) => (outcome: Outcome): T =>
  m[outcome]

export const outcomeFromPercent = (p: Percent): Outcome =>
  unPercent(p) > 0.5 ? 'yea' : 'nay'
