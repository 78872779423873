import { History } from "history";
import * as React from "react";
import { hot } from "react-hot-loader/root";
import * as Loadable from "react-loadable";
import { Route, Router, Switch } from "react-router-dom";
import { ScrollManager } from "react-scroll-manager";
import { Env } from "../../../lib/env";
import { CenteredLoader } from "../../../materials/index";
import { Runtime } from "../../runtime";
import * as R from "./routes";

interface Props {
  history: History;
  db: firebase.firestore.Firestore;
}

export const App = hot(({ history, db }: Props) => (
  <Runtime history={history} appVariant="election">
    <Env.Provider value={{ history, db }}>
      <ScrollManager history={history}>
        <Router history={history}>
          <Switch>
            <Route path={R.home} exact component={pages.home} />
            <Route path={R.nr(":year")} exact component={pages.nr} />
            <Route path={R.nr(":year", ":cantonId")} exact component={pages.nrCanton} />
            <Route path={R.sr(":year")} exact component={pages.sr} />
            <Route path={R.party(":year", ":partyId")} exact component={pages.party} />
            <Route
              path={R.partyMuniAbs(":year", ":partyId")}
              exact
              component={pages.partyMuniAbs}
            />
            <Route
              path={R.partyMuniDiff(":year", ":partyId")}
              exact
              component={pages.partyMuniDiff}
            />
            <Route path="*" component={pages.notFound} />
          </Switch>
        </Router>
      </ScrollManager>
    </Env.Provider>
  </Runtime>
));

const pages = {
  home: Loadable({
    loader: () => import(/* webpackChunkName: "home" */ "./pages/home"),
    loading: () => <CenteredLoader />
  }),
  nr: Loadable({
    loader: () => import(/* webpackChunkName: "nr" */ "./pages/nr"),
    loading: () => <CenteredLoader />
  }),
  nrCanton: Loadable({
    loader: () => import(/* webpackChunkName: "nr-canton" */ "./pages/nr-canton"),
    loading: () => <CenteredLoader />
  }),
  sr: Loadable({
    loader: () => import(/* webpackChunkName: "sr" */ "./pages/sr"),
    loading: () => <CenteredLoader />
  }),
  party: Loadable({
    loader: () => import(/* webpackChunkName: "party" */ "./pages/party"),
    loading: () => <CenteredLoader />
  }),
  partyMuniAbs: Loadable({
    loader: () => import(/* webpackChunkName: "party-m-abs" */ "./pages/party-m-abs"),
    loading: () => <CenteredLoader />
  }),
  partyMuniDiff: Loadable({
    loader: () => import(/* webpackChunkName: "party-m-diff" */ "./pages/party-m-diff"),
    loading: () => <CenteredLoader />
  }),
  notFound: Loadable({
    loader: () => import(/* webpackChunkName: "404" */ "../../../pages/404"),
    loading: () => <CenteredLoader />
  })
};
