import { rgb } from "d3-color";
import { scaleThreshold, scaleQuantize } from "d3-scale";
import { caseOutcome, Outcome, Percent, unPercent } from "../domain/index";
import { blue, green, grey, purple } from "./colors/raw";
export * from "./colors/raw";

export type CSSColor = string;

export const white = "#ffffff";
export const black = "#000000";

// ----------------------------------------------------------------------------
// Text colors

export const text: CSSColor = grey["900"];
export const lightGrey: CSSColor = grey["400"];

export const highlight: CSSColor = blue["700"];

// ----------------------------------------------------------------------------
// TA colors

export const taBlue: CSSColor = "#007abf";
export const taBlueDarker: CSSColor = "#02659c";

// ----------------------------------------------------------------------------
// yae / nay

export const yea: CSSColor = green["700"];
export const nay: CSSColor = purple["700"];

export const outcomeToColor: (outcome: Outcome) => CSSColor = caseOutcome({ yea, nay });

export const percentColorScale = scaleThreshold<number, string>()
  .domain([0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7])
  .range(
    [
      green["800"],
      green["700"],
      green["500"],
      green["400"],
      green["200"],
      purple["200"],
      purple["400"],
      purple["500"],
      purple["700"],
      purple["800"]
    ].reverse()
  );

export const percentToColor = (p: Percent): CSSColor => percentColorScale(unPercent(p));

export const percentDifferenceColorScale = scaleQuantize<string>()
  .domain([-1, +1])
  .range(
    [
      green["800"],
      green["700"],
      green["500"],
      green["400"],
      purple["400"],
      purple["500"],
      purple["700"],
      purple["800"]
    ].reverse()
  );

export function blendColor(c1: CSSColor, c2: CSSColor, a: number) {
  const { r: r1, g: g1, b: b1 } = rgb(c1);
  const { r: r2, g: g2, b: b2 } = rgb(c2);

  const a1 = 1 - a;
  const a2 = a;

  return rgb(r1 * a1 + r2 * a2, g1 * a1 + g2 * a2, b1 * a1 + b2 * a2);
}

export type PartyColorPalette = [CSSColor, CSSColor, CSSColor, CSSColor, CSSColor];
export function partyColorPalette(baseColor: CSSColor): PartyColorPalette {
  return [
    blendColor("#FFFFFF", baseColor, 0.25).hex(),
    blendColor("#FFFFFF", baseColor, 0.5).hex(),
    blendColor("#FFFFFF", baseColor, 0.75).hex(),
    baseColor,
    blendColor(baseColor, "#000000", 0.25).hex()
  ];
}

/**
 * This color palette is used during loading, or when the real color is not available.
 */
export const fallbackPartyColorPalette = partyColorPalette("#999999");
